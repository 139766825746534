import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import styled from "styled-components"
import Button from "../components/Button/Button"
import Faq from "../components/Faq/Faq"
import { Link } from "gatsby"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import RichText from "../components/RichText"

const PageTemplateStyles = styled.div`
  .container {
    display: flex;
    flex-wrap: wrap;
    .column {
      flex: 0 0 100%;

      @media (min-width: 768px) {
        flex-basis: 50%;

        &:nth-child(1) {
          padding-right: 20px;
        }

        &:nth-child(2) {
          padding-left: 20px;
        }

        > * {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      > * {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
`


const DefaultTemplate = contentfulPage => {
  const headerImage = getImage(contentfulPage.headerImage)
  const cvlink = contentfulPage.attachment.file.url
  return (
    <>
      <Seo title={contentfulPage.title} />
      <Layout>
        <SimpleBanner title={contentfulPage.title} subTitle={contentfulPage.introduction}>
          <GatsbyImage className="banner__image" image={headerImage} />
        </SimpleBanner>
        <div className="section">
          {contentfulPage.faqs ? (
            <PageTemplateStyles className="section">
              <div className="container container__tight">
                {contentfulPage.mainContent && (
                  <div className="column">
                    <RichText richText={contentfulPage.mainContent} />
                    {contentfulPage.attachment && (
                      <>
                        <Button
                          className="btn"
                          text="Download CV"
                          as={Link}
                          to={cvlink}
                        />
                        <br></br>
                        <br></br>
                        <br></br>
                      </>
                    )}
                  </div>
                )}
                {contentfulPage.faqs && (
                  <div className="column">
                    {contentfulPage.faqs.map((item, index) => {
                      return (
                        <Faq
                          key={index}
                          title={item.question}
                          description={item.answer}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            </PageTemplateStyles>
          ) : (
            <div className="container container__tight">
              <RichText richText={contentfulPage.mainContent} />
            </div>
          )}
        </div>
      </Layout>
    </>
  )
}

export default DefaultTemplate